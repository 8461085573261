@import "../../colors";

.selected {
  background-color: $accent;
  color: white;
}

.page-options {
  line-height: 0;
}

.task-wrapper {
  height: 100%;

  .task-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-bottom: 0.8rem;
      font-size: 0.7rem;
    }
  }
}

.add-task-container {
  display: flex;

  div {
    display: inline-block;
    width: 100%;
  }

  button {
    line-height: 1.25rem;
    font-size: 0.75rem;
    background-color: $accent;
    border: 1px solid $accent;
    color: white;
    padding: 4px 7px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: background-color 0.5s;

    &:focus {
      outline: 0;
      border: 1px solid $body;
    }

    &:hover {
      background-color: $lightbody;
      border: 1px solid $accent;

      svg {
        color: $accent;
      }
    }

    svg {
      width: 1rem;
      height: 1rem;
      transition: color 0.5s;
    }
  }
}

.task-list-container {
  p {
    color: var(--on-component-text-color);
  }
}
