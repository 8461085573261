@import "../../../colors.scss";

.about-page {
  h1,
  a {
    color: $permanentAccent;
  }
}

button.sign-up-now {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 329px;
  background: $permanentAccent;
  color: white;
  font-size: 1.25rem;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 0px 5px 1px $lightbody;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px 1px white;
    background: $brightAccent;
  }
}

div.cards {
  display: flex;
  justify-content: space-around;
}

div.video {
  display: flex;
  align-items: center;

  .dashboi-video {
    position: relative;
  }

  .video-section-text {
    position: relative;

    ul {
      li {
        line-height: 30px;
      }
    }
  }
}

.components-link {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  div.video {
    flex-direction: column;
  }

  div.cards {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) {
  div.cards {
    flex-direction: row;
  }

  div.video {
    flex-direction: row;

    .video-section-text {
      margin-left: 100px;
    }
  }
}

.section-header {
  text-align: center;
  margin-bottom: 50px;
}
