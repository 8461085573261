@import "../../colors.scss";

.center-pane {
  width: 850px;
  height: calc(100vh - 130px);
  overflow-y: auto;
  margin: auto;
  margin-top: 60px;
  padding: 30px;
  background-color: $lightbody;
  border: 1px solid #9ea8a6;
  overflow: auto;

  &.mobile-view {
    width: 100%;
  }
}
