@import "../../colors.scss";

.task-container {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid gray;
  font-size: 1rem;

  &:last-of-type {
    border-bottom: none;
  }

  .icon-wrapper {
    height: 1em;
    width: 1em;
    margin-left: 3px;
    transform: translateY(-1px);

    svg {
      vertical-align: baseline !important;
    }

    &:focus {
      outline: 1px solid $accent;
    }
  }

  .task-name {
    width: 100%;
    font-size: 1rem;
    display: inline-block;
    vertical-align: bottom;
    margin-left: 2px;

    .task-input {
      background: transparent;
      border: none;
      margin-bottom: 0;
      width: 100%;
      border: 2px solid transparent;
    }
  }

  .icon-text {
    margin-left: 5px;
  }
}
