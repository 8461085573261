@import "../../colors.scss";

.login-container {
  margin: 20px 0px 10px 0px;

  .banner {
    margin: 10px 0px;
  }
}

.email-login {
  display: flex;
  flex-direction: column;

  .sign-up-call-to-action {
    text-align: center;
    margin-top: 20px;
  }

  .forgot-account {
    text-align: center;
    font-size: 0.85rem !important;
  }
}
