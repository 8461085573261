.page {
  &.show-header {
    min-height: calc(100vh - 50px);
    margin-top: 50px;
  }

  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
