@import "../../colors.scss";

.banner {
  padding: 5px 0px;
  margin: 25px 0px;
  text-align: center;

  &.positive {
    background: #e5fae5;
    border: 2px solid $positive;
    color: $positive;
  }

  &.negative {
    background: #fae9e5;
    border: 2px solid $negative;
    color: $negative;
  }

  &.neutral {
    background: #ddd;
    border: 2px solid #666;
    color: #666;
  }

  .banner-close {
    margin: 2px 5px 0px 0px;
    float: right;
  }
}

.global-banner {
  margin: 0px 10px;
}
