@import "../../colors.scss";

.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .card-label {
    margin-top: 20px;
    align-self: flex-start;
  }

  .validation-error {
    align-self: flex-start;
    margin-top: 5px;
  }

  .StripeElement {
    width: 100%;
  }

  .card-input {
    border: 2px solid $lightGray;
    padding: 5px;
    width: 100%;

    &.has-focus {
      border: 2px solid $permanentAccent;
    }
  }

  button {
    margin-top: 20px;
  }
}
