@import "../../colors.scss";

.input-field {
  margin-bottom: 10px;

  span.input-error {
    font-size: 0.75rem;
    color: $negative;
    margin-left: 4px;
  }
}

input.text-input {
  width: 100%;
  line-height: 1.5rem;
  text-indent: 5px;
  border: 2px solid $lightGray;
  border-radius: 5px;

  &.has-validation-error {
    border: 2px solid $negative;
  }

  &:focus {
    outline: none;
    border: 2px solid $accent;
    text-indent: 5px;
  }

  &.uses-permanent-accent {
    &:focus {
      border-color: $permanentAccent;
    }
  }
}

span.input-label {
  display: block;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 3px;

  span.asterisk {
    font-weight: bolder;
    color: $negative;
  }
}

.help-text-icon {
  color: $accent;
  margin-left: 5px;
  transform: translateY(-1px);
}
