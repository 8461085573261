.radio-button-input {
  margin-bottom: 20px;

  .radio-button-choices {
    .radio-button {
      display: flex;
      align-items: center;

      input[type="radio"] {
        margin: 0px 5px 0px 5px;
      }
    }

    &.horizontal {
      display: flex;

      .radio-button {
        margin-right: 20px;
      }
    }
  }
}
