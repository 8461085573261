@import "../../colors.scss";

.footer {
  height: 60px;
  background: $fontColor;
  display: flex;
  align-items: center;

  p,
  a {
    color: white;
  }
}

@media (min-width: 0px) {
  .footer {
    padding: 0px 30px;
  }
}

@media (min-width: 768px) {
  .footer {
    padding: 0px 100px;
  }
}
