@import "../../colors.scss";

.button-footer-layout {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $lightGray;
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  margin-top: 25px;
  width: 100%;

  .primary {
    background-color: $permanentAccent;
    color: white;
    border: 1px solid $permanentAccent;
  }
}
