@import "../../colors.scss";

.sign-up-container {
  width: 80%;
  display: flex;
  background: white;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.5);
  margin: auto;
  height: calc(100vh - 130px);

  .sign-up-fields-container {
    background: white;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    position: relative;

    .sign-up-header {
      .site-name-container {
        width: 166px;
        margin: auto;
      }

      h3 {
        text-align: center;
        margin-top: 10px;
      }
    }

    .sign-up-fields {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: 0px 30px;

      button {
        align-self: center;
      }
    }
  }

  .sign-up-quote-container {
    position: relative;
    width: 100%;
    flex: 3;

    .background-image {
      height: 100%;
      background-size: cover;
      position: absolute;
      display: block;
      width: 100%;
    }

    .sign-up-quote {
      background-image: linear-gradient(
        to top right,
        rgba(107, 36, 80, 0.8),
        rgba(45, 48, 71, 0.8),
        rgba(107, 36, 80, 0.8)
      );
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      .sign-up-quote-elements {
        width: 50%;
        margin: 0;
        padding: 0;
        margin-left: 60px;

        h1 {
          font-size: 30px;
          color: white;
        }

        p {
          color: white;
        }
      }
    }
  }
}
