@import "../../colors.scss";

.feedback-area {
  border: 2px solid $lightGray;
  min-width: 100%;
  height: 100px;
  font-size: 1rem;
  padding: 5px;
  margin-bottom: 10px;

  &:focus {
    outline: none;
    border: 2px solid $accent;
  }
}

.sentiment-score-choices {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;

  .sentiment-score-choice {
    border: 2px solid $lightbody;

    svg {
      width: 50px;
      height: 50px;
      transform: none;
      color: $lightGray;
    }

    &.selected, &:focus {
      // border: 2px solid $accent;
      background-color: $lightbody;

      svg {
        color: $accent;
      }
    }
  }
}
