@import "../../colors.scss";

div.title-and-logo {
  display: flex;
  align-items: center;

  h1.modal-title {
    line-height: 2rem;
    margin-bottom: 0;
    color: $permanentAccent;
  }

  img.app-logo {
    margin-right: 10px;
  }
}
