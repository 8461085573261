@import "../../colors.scss";

.horizontal-slider {
  height: 10px;
  margin-top: 10px;

  .track {
    border-bottom: 2px solid $accent;
  }

  .thumb {
    transform: translateY(-7px);
    padding: 2px;
    background: $accent;
    box-shadow: 0px 0px 1px 2px $lightGray;
    border-radius: 10px;
    width: 15px;
    height: 15px;

    &:focus {
      box-shadow: 0px 0px 1px 2px $darkGray;
      outline: none;
    }
  }
}
