.custom-swatch-picker {
  width: 280px;
  display: flex;
  flex-direction: column;
  background: white;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 5px 0px;

    .custom-swatch {
      width: 50px;
      height: 20px;
      margin-bottom: 3px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
