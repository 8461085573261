@import "../../../colors.scss";

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 10px;

  &.showing-actions {
    margin-left: 17px;
  }

  &.no-photo {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
      transform: none;
    }
  }

  &:hover,
  &:focus,
  &.active {
    -webkit-box-shadow: 0px 0px 0px 2px $lightGray;
    -moz-box-shadow: 0px 0px 0px 2px $lightGray;
    box-shadow: 0px 0px 0px 2px $lightGray;
    cursor: pointer;
  }
}

.avatar-wrapper {
  &:focus {
    outline: none;

    .user-avatar {
      -webkit-box-shadow: 0px 0px 0px 2px $lightGray;
      -moz-box-shadow: 0px 0px 0px 2px $lightGray;
      box-shadow: 0px 0px 0px 2px $lightGray;
    }
  }
}

.user-menu {
  position: fixed;
  top: 54px;
  right: 5px;
  width: 175px;
  background: $body;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  color: black;
  z-index: 2;

  .user-menu-choices {
    display: block;
    padding: 5px;

    .user-menu-choice {
      padding: 5px;
      cursor: pointer;
      width: 100%;
      color: black;
      display: inline-block;
      margin-bottom: 2px;
      font-size: 14px;

      svg {
        margin-right: 5px;
        padding-bottom: 3px;
        transform: none;
        color: $permanentAccent;
      }

      &:hover,
      &:focus {
        color: white;
        outline: none;
        text-decoration: none;

        svg {
          color: white;
        }
      }

      &:hover {
        background: $permanentAccent;
      }

      &:focus,
      &.active {
        background: $permanentLightAccent;
      }
    }

    .user-menu-divider {
      height: 1px;
      width: 100%;
      margin: 3px 0px;
      background: #aaa;
      display: block;
    }
  }
}

.up-arrow {
  width: 0;
  height: 0;
  position: fixed;
  top: 46px;
  right: 21px;
  z-index: 3;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 9px;
    border: 6px solid $body;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -1px 1px 2px -1px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px 1px 2px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 1px 2px -1px rgba(0, 0, 0, 0.75);
    border-color: transparent transparent $body $body;
  }
}
