@import "../../colors.scss";

.clickable-text {
  background: none;
  border: none;
  padding: 0;
  font-size: 0.85rem;

  &:hover,
  &:focus {
    text-decoration: underline;
    outline: none;
  }

  &.light-background {
    color: $accent;
  }

  &.dark-background {
    color: white;
  }
}
