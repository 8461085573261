.rich-text-container {
  p,
  ul > li,
  ol > li {
    color: var(--on-component-text-color) !important;
    font-family: var(--body-font-family), var(--body-font-decoration) !important;
  }

  ol,
  ul {
    margin-left: 0.5rem;
    padding-left: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--header-font-family), var(--header-font-decoration) !important;
    color: var(--accent-color) !important;
  }
}

.rich-text-editor-container {
  .rich-text-button {
    margin-top: 24px;
  }
}
