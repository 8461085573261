@import "../../colors.scss";

div.pricing {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  div.pricing-option {
    width: 300px;
    border: 1px solid $lightGray;
    box-shadow: 0px 0px 5px 1px $lightGray;

    &:last-of-type {
      h2 {
        background: rgb(127, 36, 80);
        background: linear-gradient(
          90deg,
          rgba(127, 36, 80, 1) 0%,
          rgba(207, 144, 183, 1) 100%
        );
        color: white;
      }
    }

    h2 {
      text-align: center;
      color: black;
      padding: 10px;
      background-color: $lightestGray;
      font-size: 1.571rem;
    }

    .pricing-option-details {
      padding: 10px;
      text-align: center;
      border-bottom: 1px solid $lightGray;

      &:last-of-type {
        border-bottom: none;
      }

      h1.cost {
        margin-bottom: 10px;
        font-size: 1.143rem;

        span {
          font-size: 2.857rem;
        }
      }
    }

    h1 {
      text-align: center;
    }
  }

  &.mobile-view {
    div.pricing-option {
      width: 150px;

      h2 {
        font-size: 1.143rem;
      }

      h1.cost {
        font-size: 0.8571rem;

        span {
          font-size: 1.714rem;
        }
      }

      p {
        font-size: 0.7857rem;
      }

      .feature {
        span {
          font-size: 0.7143rem;
        }
      }
    }
  }
}
