@import "../../colors.scss";

.login-footer {
  text-align: center;

  p,
  a {
    font-size: 10px;
  }
}
