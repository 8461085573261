@import "../../colors.scss";

.react-grid-item > .react-resizable-handle {
  width: 10px !important;
  height: 10px !important;
  right: 1px !important;
  bottom: 1px !important;
  background: $lightbody;
  border-radius: var(--component-border-radius);
}
