@import "../../colors.scss";

.feed-story {
  padding: 10px;
  border-bottom: 1px solid gray;
}

.feedStory svg {
  margin-right: 10px;
  flex: 1;
}

.feedStory-body {
  flex: 9;
}

.featured-image-container {
  max-height: 200px;
  overflow-y: hidden;
}

.featured-image {
  width: 100%;
  display: block;
  height: auto;
}

.featured-story {
  position: relative;
}

.featured-story-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--featured-news-background-color);
  padding: 10px;
  cursor: pointer;

  .featured-story-name {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .featured-story-details {
    margin-top: 0.5rem;
    font-size: 0.7rem;
  }

  h3 {
    color: $accent;
  }
}

.story-author {
  font-size: 0.7rem;
  color: var(--on-component-text-color);
}
