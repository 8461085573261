@import "../../colors.scss";

.form-label {
  line-height: 20px;
}

.settings-container {
  .input-label {
    font-size: 12px;
    margin-left: 0px;
    color: $fontColor;
  }

  input {
    font-size: 12px;
    line-height: 24px;
  }
}
