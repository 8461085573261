@import "../../colors.scss";

.react-clock__face {
  background-color: $lightbodyOpaque;
}

.digital-face {
  display: flex;

  &.large {
    font-size: 7.75rem;
  }

  &.medium {
    font-size: 5.75rem;
  }

  &.small {
    font-size: 4.75rem;
  }

  div {
    font-weight: bold;
    color: $lightbody;
  }

  &.left {
    position: absolute;
    left: 0;
  }

  &.center {
    margin: auto;
  }

  &.right {
    position: absolute;
    right: 0;
  }
}
