@import "../../colors.scss";

div.sidebar-unordered-list {
  width: 100%;
  float: left;
  list-style-type: none;
  padding-left: 0;

  .single-widget,
  .widget-group {
    width: 200px;
    padding: 7px 10px 10px 10px;
    text-align: left;
    color: $fontColor;

    svg,
    span {
      color: $fontColor;
    }
  }

  .single-widget:hover,
  .widget-group:hover,
  .selected-option {
    background-color: $permanentAccent;
    cursor: pointer;

    > div > svg {
      color: white !important;
    }

    > div > span {
      color: white !important;
    }
  }

  .widget-group {
    position: relative;

    .widget-group-popup {
      border-left: 1px solid $darkGray;
      display: block;
      position: absolute;
      left: 200px;
      top: 0px;
      background-color: $body;

      .single-widget {
        padding: 7px 10px 10px 10px;
      }
    }

    .more-options {
      float: right;
      margin-top: 5px;
    }
  }

  .selected-option {
    background-color: $permanentLightAccent;

    > span,
    > .sidebar-icon svg {
      color: white;
    }
  }
}
