.content-elements {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  > div {
    margin-right: 5px;
  }
}

.header-elements {
  height: auto;
}
