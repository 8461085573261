.credit-badge {
  background-color: black;
  color: white !important;
  text-decoration: none;
  padding: 4px 6px;
  font-size: 0.4rem;
  font-weight: bold;
  line-height: 1.2;
  display: inline-block;
  border-radius: 3px;
  position: relative;
  bottom: 0px;
  width: fit-content;
  right: 0px;

  span {
    display: inline-block;
    padding: 2px 3px;
    font-size: 0.4rem;

    svg {
      height: 6px;
      width: auto;
      position: relative;
      vertical-align: middle;
      top: -1px;
      fill: white;
    }
  }
}
