@import "../../colors.scss";

button {
  overflow: hidden;
}

button.standard-button {
  // width: 60%;
  outline: none;
  border: none;
  min-height: 40px;
  border: 2px solid $accent;
  background: transparent;
  position: relative;
  color: black;
  transition: all 0.5s ease;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Questrial", sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
  border-radius: 20px;
  margin-top: 5px;
  padding: 0px 20px;
  display: block;
  margin: auto;
  z-index: 2;

  &.use-permanent-accent {
    border: 2px solid $permanentAccent;
    span {
      &:before {
        background: $permanentAccent;
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.35s ease;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    border-radius: 20px;

    &:before {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      width: 0;
      height: 100%;
      background: $accent;
      transition: all 0.5s ease;
      z-index: -1;
      border-radius: 20px;
    }
  }

  &:hover,
  &:focus {
    color: white;

    span {
      opacity: 1;

      &:before {
        width: 100%;
      }
    }

    * {
      color: white;
    }
  }

  * {
    color: black;
    transition: all 0.5s ease;
  }
}

button.opaque-button,
button.secondary-button {
  border-radius: 5px;
  font-size: 14px;
  text-transform: none;
  color: var(--on-accent-text-color);
  min-height: 0;
  padding: 5px 15px;
  border: none;
  margin-top: 0px;
  min-width: 100px;

  &.dark-background {
    &:hover,
    &:focus {
      -webkit-box-shadow: 0px 0px 0px 2px $lightGray;
      -moz-box-shadow: 0px 0px 0px 2px $lightGray;
      box-shadow: 0px 0px 0px 2px $lightGray;
      outline: none;
    }
  }

  &.light-background {
    &:hover,
    &:focus {
      border: 1px solid $lightbody;
      outline: 2px solid $darkGray;
    }
  }
}

button.opaque-button {
  background: $accent;
  border: 1px solid $accent;

  &.use-permanent-accent {
    background: $permanentAccent;
    border: 1px solid $permanentAccent;
  }
}

button.secondary-button {
  background: $lightGray;
  border: 1px solid $lightGray;
}
