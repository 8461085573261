@import "../../../colors.scss";

div.card {
  height: 200px;
  padding: 20px;
  text-align: center;
  border: 1px solid $lightGray;
  box-shadow: 0px 0px 5px 1px $lightGray;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: white;

  svg.card-icon {
    width: 100%;
    color: $permanentAccent;

    &:hover {
      transform: none;
      cursor: auto;
    }
  }

  h1,
  p {
    margin: 0;
  }

  h1,
  a {
    color: $permanentAccent;
  }
}

@media (max-width: 767px) {
  div.card {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  div.card {
    width: 300px;
  }
}
