@import "../../colors.scss";

.bookmark-table {
  border-collapse: collapse;
  margin-bottom: 10px;
}

.bookmark-list-form {
  tr.add-bookmark-row {
    line-height: 30px;
  }

  tr.header-label-row {
    line-height: 25px;
  }

  th {
    color: $fontColor;
    padding-left: 5px;
    text-align: left;
  }

  td {
    width: 50%;
    padding: 0;

    .input-field {
      margin-bottom: 2px;
    }

    input {
      margin-bottom: 0px;
      border-radius: 0;
    }

    svg {
      color: $permanentAccent;
      &:focus {
        outline: 1px solid $permanentAccent;
      }
    }
  }
}

.bookmark-list-category {
  h4 {
    text-align: center;
  }
}

.bookmarks {
  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.remove-bookmark {
  cursor: pointer;
  margin-bottom: 5px;
}

.add-bookmark {
  margin-left: 5px;
  cursor: pointer;

  button {
    color: $permanentAccent !important;
  }
}
