.cat-container {
  position: relative;
  width: fit-content;
  margin: auto;
  display: block;

  .cat-image {
    margin: auto;
    display: block;
  }
}
