@import "../../colors.scss";

.subtle-separator {
  display: flex;
  color: $darkGray;
  span {
    font-size: 14px;
  }

  .divider {
    flex-grow: 1;
    border-bottom: 1px solid $darkGray;
    margin-bottom: 7px;

    &.first {
      margin-right: 5px;
    }

    &.last {
      margin-left: 5px;
    }
  }
}
