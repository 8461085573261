$permanentAccent: rgba(107, 36, 80, 1);
$permanentAccentTranslucent: rgba(107, 36, 80, 0.8);
$permanentLightAccent: rgba(181, 145, 167, 1);
$permanentLightAccentTranslucent: rgba(61, 214, 208, 0.5);
$accent: var(--accent-color);
$lightAccent: var(--secondary-accent-color);
$brightAccent: #932669;
$onSchemeAccent: #048ba8;
$fontColor: #2d3047;
$lightestGray: #e1e3e1;
$lightGray: #9ea8a6;
$darkGray: #575c5b;
$body: #d7d9d7;
$lightbody: #f4f4f4;
$lightbodyOpaque: rgba(244, 244, 244, 0.8);
$opaque: rgba(215, 217, 215, 0.6);
$backgroundOverlay: var(--component-background-color);
$negative: #d11d1a;
$positive: #107f3c;
