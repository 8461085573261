@import "../../colors";
@import "../../variables.scss";

#topbar {
  display: flex;
  align-items: center;
  height: inherit;
  width: 100%;
  z-index: 2;
  min-height: 50px;
  position: fixed;
  background: $fontColor;
  border-bottom: 1px solid $darkGray;
  padding: 0px 10px;

  &.mobile-view {
    justify-content: space-between;
  }

  &.app {
    background: transparent;
    border-bottom: none;
  }

  &.edit-layout {
    background-color: $fontColor;
  }
}

.site-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $permanentAccent;
  padding: 3px 10px;
  transform: skew(-20deg);
  border-radius: 5px;
  margin-left: 13px;

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.site-name {
  margin: 0;
  font-size: 20px;
  color: $body;
  margin-left: 10px;
  padding: 3px 5px 3px 45px;
}

.login-text {
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.mobile-view {
    position: fixed;
    right: 0;
  }

  div {
    display: inline-block;
  }

  .welcome-message {
    &.with-margin {
      margin-left: 10px;
    }

    &:after {
      content: "";
      border-right: 1px solid white;
      margin: 10px 20px;
    }
  }

  span:last-child {
    margin-right: 10px;
  }
}

.login-option {
  cursor: pointer;
  font-size: 1rem !important;

  &:focus {
    text-decoration: underline;
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

.topbar-options {
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;

  .topbar-link {
    color: white;
    text-decoration: none;
    font-size: 14px;

    &:hover,
    &:focus {
      text-decoration-style: solid;
      text-decoration: underline;
      outline: none;
    }

    &:after {
      content: "";
      border-right: 1px solid white;
      margin: 10px 20px;
    }
  }
}

.login-options {
  display: flex;
  flex-direction: row;
  align-items: center;

  span,
  button {
    margin-right: 10px;
  }
}

.lock-layout-icon {
  margin-right: 8px;
  color: white;
}

.menu-links {
  display: flex;
  align-items: center;
  margin-left: 20px;
  min-height: 50px;

  .configure-link {
    position: relative;
    cursor: pointer;

    .configure-link-icon {
      color: white;
      transform: translateY(-1px);
    }

    .configure-link-text {
      font-size: 14px;
    }

    .component-picker-wrapper {
      line-height: 21px;
      position: absolute;
      background: $body;
      left: 0;
      -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.5);
    }
  }

  .menu-link,
  .configure-link {
    padding: 0px 20px;
    color: white;
    line-height: 50px;
    font-size: 14px;
    position: relative;

    &:hover,
    &.active {
      text-decoration: none;
      background-color: #404257;
    }

    &:focus {
      outline: 2px solid $lightGray;
    }
  }
}

.hamburger-menu {
  color: white;
  z-index: 2;
  position: fixed;
}

.icon-button {
  color: white;
  transform: translateY(-1px);

  &:hover {
    transform: translateY(-1px);
  }
}

@keyframes wiggle {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.save-alert {
  position: fixed;
  background: $permanentLightAccent;
  top: 54px;
  right: 24px;
  animation: wiggle 1.5s infinite;
  animation-timing-function: ease;
  padding: 5px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.75);
  transform-origin: bottom;
  font-size: 14px;
  color: white;

  svg {
    color: white;
  }
}

.up-arrow-save {
  width: 0;
  height: 0;
  position: fixed;
  top: 46px;
  right: 115px;
  z-index: 1;
  animation: wiggle 1.5s infinite;
  animation-timing-function: ease;
  transform-origin: bottom;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 9px;
    border: 6px solid $permanentLightAccent;
    border-color: transparent transparent $permanentLightAccent
      $permanentLightAccent;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -1px 1px 2px -1px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px 1px 2px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 1px 2px -1px rgba(0, 0, 0, 0.75);
  }
}
