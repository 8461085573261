@import "../../variables.scss";
@import "../../colors.scss";

.widget-container {
  height: 100%;
  border-radius: var(--component-border-radius);
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: inherit;
  border-radius: var(--component-border-radius);

  &.edit-mode {
    outline: 2px dotted white;
  }
}

.wrapper:hover {
  box-shadow: black;
}

.widget-header {
  flex: 1;
  height: 20px;
  display: flex;
  width: calc(100% - 2px);
  position: fixed;
  background-color: $lightbodyOpaque;
  z-index: 1;
}

.widget-body {
  overflow-x: hidden;
  position: fixed;
  width: calc(100% - 2px);
  padding: 10px;
  top: 0px;
  bottom: 0px;
  flex: 1;
  overflow-y: auto;
  background: $backgroundOverlay;
  border: 1px solid var(--component-border-color);
  border-radius: var(--component-border-radius);

  p,
  li,
  label,
  th,
  tr {
    color: var(--on-accent-text-color);
  }
}

.body_clock,
.body_google_search,
.body_ticker_banner,
.body_market_overview,
.body_welcome_message {
  background-color: transparent !important;
  display: flex;
  overflow-y: hidden;
  padding: 0px !important;
  width: 100%;
  border-radius: 0px;
  border: none;
}

.body_google_search {
  justify-content: center;
}

.body_welcome_message {
  align-items: center;
}

.body_clock {
  justify-content: start;
}

.wrapper_clock,
.wrapper_google_search,
.wrapper_ticker_banner,
.wrapper_market_overview,
.wrapper_welcome_message {
  border: none;
}

.locked {
  top: 0;
  bottom: 0;
  padding: 10px;
}

.draggable-handle {
  height: inherit;
  flex: 1;
  padding: 2px 0px 0px 2px;
  cursor: grab;

  svg {
    color: rgba(0, 0, 0, 0.6);
    float: left;
    cursor: grab;
  }

  .grabbing {
    cursor: grabbing;
  }
}

.grabbing {
  cursor: grabbing !important;
}

.remove-widget-icon {
  width: 18px;
  padding: 2px 0px 0px 2px;

  svg {
    color: rgba(0, 0, 0, 0.6);
    float: right;
    transition: transform $iconTransformTime;
    cursor: pointer;
  }

  svg:hover {
    transform: scale($iconScale);
  }
}
