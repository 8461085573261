.welcome-message {
  text-align: center;
  width: 100%;

  h1 {
    margin-bottom: 0;
    font-size: 3em;
    color: white;
  }
}
