@import "../../colors.scss";

.dropdown-field {
  margin-bottom: 10px;

  .clickable-dropdown-input {
    width: inherit;
    line-height: 26px;
    text-indent: 5px;
    border: 2px solid $lightGray;
    background: white;
    cursor: pointer;
    color: $lightGray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    border-radius: 5px;

    &:focus {
      border: 2px solid $accent;
      outline: none;
    }

    .dropdown-caret {
      color: black;
      float: right;
      margin-right: 10px;
      transition: all 0.3s ease;
      transform: rotate(0deg);

      &.dropdown-visible {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdown-choices {
  min-width: 100px;
  background: white;
  border: 2px solid $lightGray;
  position: fixed;
  z-index: 10;
  max-height: 300px;
  overflow-y: scroll;

  .dropdown-choice {
    width: 100%;
    cursor: pointer;
    line-height: 25px;
    border-bottom: 1px solid $lightGray;
    text-indent: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background: $lightbody;
      color: var(--on-accent-text-color);
    }

    &.selected {
      background: $accent;
      color: var(--on-accent-text-color);

      &:hover {
        background: $lightAccent;
      }
    }

    &.focused {
      background: $lightAccent;
    }
  }
}
