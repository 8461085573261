@import "../../colors.scss";

.checkbox-row {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    margin-right: 5px;

    &:focus {
      box-shadow: 0px 0px 5px 2px $accent;
      border-radius: 3px;
    }
  }

  label {
    margin-top: 1px;
  }
}
