#main-content {
  width: 100%;
  transition: width 0.25s;
  will-change: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.top-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  height: 50vh;
}

.bottom-row {
  display: flex;
  flex-wrap: wrap;
  height: 50vh;
  margin-bottom: 10px;
}
