@import "../../../colors.scss";

.feature {
  padding: 5px 10px;
  text-align: left;
  display: flex;

  svg.disabled {
    color: transparent;
    cursor: default;
  }

  span.disabled {
    color: $lightGray;
  }
}
