@import "../../colors.scss";

.premium-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  p {
    text-align: center;
  }

  .premium-button-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    button {
      margin-bottom: 20px;
    }
  }
}
