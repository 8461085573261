@import "colors";
@import "variables.scss";
@import "../node_modules/react-grid-layout/css/styles.css";
@import "../node_modules/react-resizable/css/styles.css";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  margin-top: 0;
  margin-bottom: 10px;
  color: $accent;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.header-layout-font {
  font-family: var(--header-font-family), var(--header-font-decoration);
}

h4 {
  font-size: 1.5rem;
}

p,
label,
li,
th,
tr,
input,
span,
a,
textarea {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.body-layout-font {
  font-family: var(--body-font-family), var(--body-font-decoration);
}

p {
  margin: 10px 0px;
}

a {
  text-decoration: none;
  color: $accent;

  &:focus,
  &:hover {
    text-decoration: underline;
    outline: none;
  }
}

svg {
  color: $accent;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  transition: transform $iconTransformTime;

  &:hover {
    transform: scale($iconScale);
  }

  &:focus {
    outline: 1px solid $accent;
  }
}

p,
li,
label,
th,
tr {
  color: $fontColor;
  line-height: 20px;
}

th {
  font-weight: bold;
}

table,
tbody {
  width: 100%;
}

.form-table,
.email-login {
  line-height: 1rem;
  display: table;
  width: 100%;
}

.form-label {
  width: 20%;
}

ul {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

button {
  -webkit-appearance: none;
  cursor: pointer;
}

.login-button {
  width: 100%;
  line-height: 2rem;
  margin-top: 5px;
  font-size: 1rem;
  background-color: $accent;
  color: white;
}

.login-button:active:hover {
  background-color: $lightGray;
  color: $fontColor;
}

.App {
  text-align: left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 4vmin;
  pointer-events: none;
}

.App-header {
  flex: 0 1 auto;
  font-size: calc(0.7143rem+ 2vmin);
  color: white;
}

.App-link {
  color: $accent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $lightbody;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
