@import "../../colors.scss";

.icon-picker-container {
  .all-icons {
    width: 300px;
    height: 300px;
    overflow-y: auto;
    position: absolute;
    background: white;
    z-index: 3;
    -webkit-box-shadow: 0px 0px 0px 2px $lightGray;
    -moz-box-shadow: 0px 0px 0px 2px $lightGray;
    box-shadow: 0px 0px 0px 2px $lightGray;

    .icon-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      height: calc(100% - 31px);
    }
  }

  .icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    border: 1px solid black;
  }

  .icon {
    width: 20px;
    height: 20px;
    color: $permanentAccent;
  }

  .icon-picker-input {
    width: calc(100% - 6px); // 6px is the margin on either side
    max-height: 25px;
    line-height: 25px;
    margin: 3px;
    border-radius: 5px;

    &:focus {
      border: 2px solid $permanentAccent;
      outline: none;
    }
  }
}
