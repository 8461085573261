@import "../../colors.scss";

.premium-slider {
  margin-bottom: 40px;

  .track {
    border-bottom: 2px solid $permanentAccent !important;
  }
}

.premium-thumb {
  position: relative;
  width: 50px !important;
  height: 50px !important;
  transform: translateY(-25px) !important;
  border-radius: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $permanentAccent !important;

  .dollar-amount {
    position: absolute;
    bottom: -50px;
    background: $permanentLightAccent;
    padding: 10px 30px;
    color: white;
  }

  svg {
    color: white;
    width: 40px;
    height: 40px;
    transform: none;
  }
}

.chosen-amount {
  margin-bottom: 40px;
}
