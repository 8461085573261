@import "../../colors.scss";

.google-icon {
  color: white;
}

.social-logins {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: auto;
    min-width: 55%;
  }
}
