.icon-text {
  width: 100%;

  svg {
    text-align: center;
    display: inline-flex;
    vertical-align: middle;
    height: 1em;
    width: 1em;
  }

  span {
    display: inline-flex;
    vertical-align: middle;
  }

  &.icon-first,
  &.icon-around {
    > :first-child {
      margin-right: 5px;
    }
  }

  &.icon-second,
  &.icon-around {
    > :last-child {
      margin-left: 5px;
    }
  }
}
