@import "../../colors";

.modal {
  width: 500px;
  position: fixed;
  top: 75px;
  right: 50%;
  left: 50%;
  margin-left: -250px;
  z-index: 2;
  background-color: $lightbody;
  padding: 30px;
  box-shadow: 0px 0px 30px 1px;
  border: 1px solid $lightGray;

  &.mobile-view {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin-left: 0;
  }
}

.modal-header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  svg {
    color: $permanentAccent;
    transition: transform 0.25s;
  }

  svg:hover {
    transform: scale(1.2);
  }
}

.close-modal-icon {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  float: right;
}

.glass {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 2;
}

.premium-only-modal {
  p {
    font-size: 0.85rem;
  }
}
