@import "../../colors.scss";

div.paragraph-field {
  &.has-bottom-padding {
    margin-bottom: 10px;
  }

  .text-area {
    border: 2px solid $lightGray;
    min-width: 100%;
    font-size: 1rem;
    padding: 5px;
    line-height: 1.1rem;
    overflow: hidden;
    color: var(--on-component-text-color);
    resize: vertical;

    &.has-validation-error {
      border: 2px solid $negative;
    }

    &:focus {
      outline: none;
      border: 2px solid $accent;
    }
  }
}
