.bookmark-container {
  display: flex;
  height: 100%;
  flex-flow: column;

  a {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
