@import "../../colors.scss";

.menu-links-mobile {
  position: fixed;
  z-index: 1;
  height: 100%;
  background: $lightbody;
  border-right: 1px solid $lightGray;
  margin-top: 50px;
  transition: transform 0.3s;
  width: 200px;

  a {
    display: block;
    padding: 20px;
    transition: color, background-color 0.25s;
    border-bottom: 1px solid $lightGray;
    font-size: 1.15rem;

    &:hover,
    &:focus {
      color: white;
      background: $accent;
      text-decoration: none;
    }

    &.active {
      color: white;
      background: $lightAccent;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &.closed {
    transform: translateX(-200px);
  }
}
