@import "../../colors.scss";

div.full-width-section {
  background: $lightbody;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  flex-direction: column;

  &.dark-background {
    background: $lightestGray !important;
  }

  &.large-padding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767px) {
  div.full-width-section {
    padding: 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  div.large-padding {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (min-width: 1200px) {
  div.large-padding {
    padding-left: 300px;
    padding-right: 300px;
  }
}
