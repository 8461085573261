.app-logo-link {
  .app-logo {
    height: 47px;
  }

  &.non-mobile-view {
    position: absolute;
    left: 0;
    top: -5px;
    transform: skew(20deg);
  }

  &.mobile-view {
    position: relative;
    margin: auto;
    transform: translateX(-5px);

    .app-logo {
      height: 40px;
    }
  }
}
