@import "../../colors.scss";

.multi-dropdown-choices {
  min-width: 100px;
  background: white;
  border: 2px solid $lightGray;
  max-height: 300px;
  overflow-y: auto;
  position: fixed;
  z-index: 4;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }
}

.multi-dropdown-choice {
  width: 100%;
  line-height: 25px;
  border-bottom: 1px solid $lightGray;
  text-indent: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:last-of-type {
    border-bottom: none;
  }

  &.selectable {
    cursor: pointer;

    &:hover,
    &.focused {
      background: $lightAccent;
    }

    &.use-permanent-accent {
      &:hover,
      &.focused {
        background: $permanentLightAccent;
      }
    }
  }

  &.not-selectable {
    background: $lightestGray;
    font-style: italic;
  }

  &.selected {
    background: $accent;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.use-permanent-accent {
      background: $permanentAccent;
      &:hover {
        background: $permanentLightAccent;
      }
    }
  }

  svg {
    color: white;
    margin-right: 5px;
  }
}

.multi-dropdown-input {
  width: inherit;
  min-height: 2rem;
  flex-grow: 2;
  padding: 2px;
  text-indent: 5px;
  border: 2px solid $lightGray;
  background: white;
  cursor: pointer;
  color: $lightGray;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  min-height: 32px;

  &:focus-within {
    border: 2px solid $accent;
  }

  &.use-permanent-accent {
    &:focus-within {
      border: 2px solid $permanentAccent;
    }
  }
}

.selections-and-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  input {
    border: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

.multi-dropdown-caret {
  color: black;
  float: right;
  margin-right: 3px;
}

div.selection-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2px;

  span.selection {
    display: flex;
    // width: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 5px;
    background: $fontColor;
    color: white;
    border-radius: 5px;
    line-height: 18px;
    padding-right: 5px;
    padding-left: 2px;
    margin-top: 2px;

    p {
      margin: 0;
      color: white;
      width: 70px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      color: white;
    }
  }
}

.focused {
  background: $lightAccent;
  color: white;
}
