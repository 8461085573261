.tradingview-widget-container,
.tradingview-widget-container__widget {
  width: 100%;
}

.tradingview-widget-copyright {
  float: right;
}

.multi-select-wrapper {
  display: flex;
  width: calc(100% - 2px);
  align-items: center;
}
