.component {
  margin-bottom: 20px;

  div.side-by-side {
    display: flex;

    img {
      width: 300px;
      height: auto;
      align-self: center;
    }
  }
}

@media (max-width: 767px) {
  div.side-by-side {
    flex-direction: column;

    > *:last-child {
      margin-left: 0px;
    }

    > div {
      margin-bottom: 20px;
    }
  }

  div.full-width-section:nth-child(even) {
    div.side-by-side {
      flex-direction: column-reverse;
    }
  }
}

@media (min-width: 768px) {
  div.side-by-side {
    flex-direction: row;

    > *:last-child {
      margin-left: 20px;
    }
  }
}
