.loading-spinner {
  display: flex;
  justify-content: center;

  svg {
    animation: spin 2s linear infinite;
    width: 1.5em;
    height: 1.5em;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-glass {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
    height: 50px;
    width: 50px;
  }
}
