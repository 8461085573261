.full-width-banner {
  background-size: cover !important;
  height: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  .banner-text {
    color: white;
    text-shadow: 0px 0px 6px grey;
    text-transform: uppercase;
    margin: auto;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .full-width-banner {
    padding: 0px 30px;
    .banner-text {
      font-size: 2.5rem;
    }
  }
}

@media (min-width: 768px) {
  .full-width-banner {
    padding: 0px 100px;
    .banner-text {
      font-size: 5rem;
    }
  }
}
