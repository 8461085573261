@import "../../colors.scss";

.global-styles-container {
  padding: 10px 10px 0px 10px;
  color: $fontColor;

  .input-label {
    font-size: 12px;
  }
}

.reset-styles {
  text-align: left;
  font-size: 0.75rem;
  color: $permanentAccent !important;
  margin-left: 3px;
}

.justified-field {
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 45px;
  align-items: center;

  > :first-child {
    flex: 2;
  }

  > :last-child {
    flex: 3;
  }
}

.split-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  > :first-child {
    flex: 1;
    margin-right: 5px;
  }

  > :last-child {
    flex: 1;
    margin-left: 5px;
  }
}

.style-section-header {
  text-transform: uppercase;
  margin-left: 3px;
  font-size: 14px;
}
